import React, {memo, useEffect, useRef} from "react"
import ReactDOM from "react-dom"
import { useInView } from "react-intersection-observer"
import AnimateSVG from "../AnimateSVG/AnimateSVG"

import { gsap, TweenMax, SplitText, DrawSVGPlugin } from "gsap/all"

import "./style.scss"

gsap.registerPlugin(DrawSVGPlugin)

function DSSTextReveal(props) {

  // InView settings
  let triggerSetting = true
  if(props.reappear === "true") {
    triggerSetting = false
  }
  const [ref, inView] = useInView({ triggerOnce: triggerSetting })

  let wrapparef = useRef()

  useEffect(() => {

    const svgData = [
      { // 1
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 2
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 3
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 4 ok
        path: "m40.5,224.453125c0,0 16,-1 44,-1c50,0 127.065674,-2.096939 202,-8c55.056244,-4.337128 107.027496,-7.788391 147,-6c31.033417,1.388458 42.087402,2.39827 51,4c4.058105,0.729309 5.292908,0.292892 6,1c0.707092,0.707108 0,1 0,2c0,0 0,0 0,0c1,0 0.823761,1.486252 3,2c2.919739,0.689255 7,0 11,0c3,0 5.823761,-1.486252 8,-2c1.946472,-0.459503 2,0 2,-1l0,0l0,-1l0,0",
        transform: "translate(0 0)",
        viewbox: "0 0 602 200",
      },
      { // 5 ok
        path: "M-267.845,0c-28.521.38-56.974,8.293-85.393,7.122-19.352-.8-38.462-2.213-57.861-2.2-32.07.022-66.053,2.661-96.022,21.041-13.047,8-25.63,17.3-29.21,38.1-1.14,6.624-2.063,17.1,1.463,22.677a29.592,29.592,0,0,0,12.143,3.85q30.341,9.567,60.893,17.713c33.63,7.59,63.892,6.732,97.193,2.392,26.595-3.467,53.176-.988,79.455-9.047A274.84,274.84,0,0,0-251.1,87.4q13.8-4.153,27.479-9.042c6.1-3.016,16.064-8.284,19.864-16.381,2.236-4.765,5.206-15.47,3.637-20.869-2.325-8-21.745-18.521-25.551-20.722-28.121-16.261-59.3-21.955-88.949-30.148a388.045,388.045,0,0,0-90.539-14.234c-21.763-.829-43.513-.542-65.279-.531",
        transform: "translate(545 0)",
        viewbox: "0 0 340 120",
      },
      { // 6 ok
        path: "M-159.45,1.312c-7.791-6.11-16.533-4.521-25-4.286-23.485.653-47.318-1.6-70.73-4.066-35.832-3.772-71.4.851-107.29.868-52.22.025-104.917-2.907-156.6,7.53-18.553,3.747-36.255.85-49.169,22.874-5.211,8.887-12.791,23.915-6.047,34.606,1.3,2.053,6.157,3.927,7.8,5,17.7,11.552,36.546,20.505,55.684,25.166,35.761,8.711,72.248,5.219,108.236,8.166,39.8,3.261,79.838-.76,119.561,3.282a330.372,330.372,0,0,0,98.223-4.885c11.865-2.478,74.414-15.767,57.128-48.739-5.771-11.008-18.893-14.722-27.073-19.535-9.434-5.552-17.894-9.99-27.707-13.227-22.446-7.405-45.515-18.647-68.161-22.7-20.23-3.617-41.785-9.957-62.982-10.369",
        transform: "translate(600 15)",
        viewbox: "0 0 500 120",
      },
      { // 7 ok
        path: "M-111.607,0c-45.054.38-90,8.293-134.9,7.122-30.571-.8-60.759-2.213-91.4-2.2-50.661.022-104.345,2.661-151.687,21.041-20.611,8-40.488,17.3-46.143,38.1-1.8,6.624-3.26,17.1,2.31,22.677a67.439,67.439,0,0,0,19.182,3.85q47.931,9.567,96.194,17.713c53.126,7.59,100.932,6.732,153.537,2.392,42.012-3.467,84-.988,125.516-9.047q27.339-5.886,53.841-14.243,21.8-4.153,43.409-9.042c9.644-3.016,25.377-8.284,31.379-16.381,3.533-4.765,8.223-15.47,5.745-20.869-3.673-8-34.351-18.521-40.363-20.722C-89.41,4.129-138.666-1.565-185.5-9.758A945.518,945.518,0,0,0-328.526-23.992c-34.379-.829-68.739-.542-103.122-.531",
        transform: "translate(550 0)",
        viewbox: "0 0 550 110",
      },
      { // 8 ok
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(620 20)",
        viewbox: "0 0 700 120",
      },
      { // 9 ok
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(600 12)",
        viewbox: "0 0 700 100",
      },
      { // 10 ok
        path: "M0,0C-56.865.38-113.6,8.293-170.259,7.122c-38.585-.8-76.687-2.213-115.364-2.2-63.941.022-131.7,2.661-191.451,21.041-26.014,8-51.1,17.3-58.239,38.1-2.273,6.624-4.114,17.1,2.916,22.677a104.909,104.909,0,0,0,24.211,3.85q60.5,9.567,121.41,17.713c67.052,7.59,127.39,6.732,193.786,2.392,53.025-3.467,106.023-.988,158.419-9.047Q-.065,95.761,33.384,87.4,60.9,83.251,88.172,78.362c12.172-3.016,32.029-8.284,39.605-16.381,4.459-4.765,10.379-15.47,7.251-20.869-4.636-8-43.356-18.521-50.944-20.722C28.015,4.129-34.153-1.565-93.265-9.758A1497.17,1497.17,0,0,0-273.783-23.992c-43.391-.829-86.758-.542-130.155-.531",
        transform: "translate(530 0)",
        viewbox: "0 0 630 120",
      },
      { // 11 ok
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(580 20)",
        viewbox: "0 0 630 100",
      },
      { // 12 ok
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(590 5)",
        viewbox: "0 0 630 100",
      },
      { // 13
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 14
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 15
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 16
        path: "M0,0C-10.766-5.715-22.847-4.229-34.551-4.009c-32.454.611-65.39-1.5-97.743-3.8-49.516-3.528-98.669.8-148.265.812-72.163.023-144.986-2.719-216.41,7.044-25.639,3.505-50.1.795-67.948,21.4-7.2,8.313-17.676,22.371-8.356,32.371,1.79,1.92,8.508,3.673,10.776,4.675,24.46,10.806,50.5,19.181,76.95,23.541,49.418,8.148,99.841,4.882,149.572,7.639,55.006,3.05,110.329-.711,165.223,3.07,43.858,3.02,92.164,1.591,135.736-4.57,16.4-2.318,102.834-14.749,78.946-45.592C35.955,32.278,17.821,28.8,6.517,24.3-6.52,19.109-18.211,14.957-31.771,11.929-62.789,5-94.669-5.514-125.964-9.3-153.92-12.684-183.707-18.615-213-19",
        transform: "translate(650 -225)",
        viewbox: "0 0 700 120",
      },
      { // 17 ok
        path: "M327.02,0C235.548.38,144.294,8.293,53.146,7.122c-62.067-.8-123.357-2.213-185.571-2.2-102.854.022-211.846,2.661-307.963,21.041-41.845,8-82.2,17.3-93.682,38.1-3.656,6.624-6.618,17.1,4.691,22.677a264.726,264.726,0,0,0,38.945,3.85q97.311,9.567,195.3,17.713c107.858,7.59,204.916,6.732,311.719,2.392,85.295-3.467,170.546-.988,254.828-9.047Q326.915,95.761,380.72,87.4q44.264-4.153,88.13-9.042c19.58-3.016,51.521-8.284,63.708-16.381,7.173-4.765,16.7-15.47,11.664-20.869-7.457-8-69.741-18.521-81.947-20.722C372.084,4.129,272.082-1.565,177-9.758,79.092-18.193-13.912-22.81-113.38-23.992c-69.8-.829-139.556-.542-209.364-.531",
        transform: "translate(550 0)",
        viewbox: "0 0 1100 120",
      },
    ]

    new SplitText(wrapparef.current, {
      type: "lines",
      linesClass: "text-line"
    })
    const mySplit = new SplitText(wrapparef.current, {
      type: "lines",
      //position: "absolute"
      linesClass: "muhlines line-++"
    })
    new SplitText(wrapparef.current, {
      type: "lines",
      linesClass: "text-line-doublewrap"
    })
    let wrappareff = mySplit.lines

    var svgHighlights = wrapparef.current.getElementsByTagName("mark")

    if(svgHighlights.length) {
      let svgNode = document.createElement("div")
      svgNode.className = "svg-highlight-layer"

      let rect = svgHighlights[0].getBoundingClientRect()
      let rectRelativeTo = wrapparef.current.getBoundingClientRect()

      let hightlightWidth = (svgHighlights[0].offsetWidth * 1.2)
      let hightlightHeight = (svgHighlights[0].offsetHeight * 1.2)
      let topOffset = (rect.top - rectRelativeTo.top - (svgHighlights[0].offsetHeight * 1.2))
      let leftOffset = (rect.left - rectRelativeTo.left)
      //svgNode.style.transform = "translateY(" + topOffset + ")"
      svgNode.style.width = hightlightWidth + "px"
      svgNode.style.height = hightlightHeight + "px"
      svgNode.style.top = topOffset + "px"
      svgNode.style.left = leftOffset + "px"
      
      let charLength = svgHighlights[0].textContent.length - 1

      ReactDOM.render(<AnimateSVG color="#131313" delay="0.75" path={svgData[charLength].path} viewbox={svgData[charLength].viewbox} transform={svgData[charLength].transform} />,wrapparef.current.appendChild(svgNode))

    }

    TweenMax.staggerTo(wrappareff, 1, {x: 0, y: 0, autoAlpha: 1}, 0.2)

  }, [ref])


  const HTag = props.size ? `${props.size}` : "h2"

  const textFormatted = props.text ? props.text.replace(/(?:\r\n|\r|\n)/g, '<br>') : null
  
  return (
	<HTag ref={ref} className={["text-reveal", props.theme, inView ? "is-inview" : null].join(" ")}>
		<span ref={wrapparef} dangerouslySetInnerHTML={{__html: textFormatted}} ></span>
	</HTag>
  )
}

export default memo(DSSTextReveal);
