import React, {memo} from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import './style.scss'


function AnimateSVG(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  const icon = {
	hidden: {
	    opacity: 0,
	    pathLength: 0,
	    fill: "#ffffff"
	},
	visible: {
	    opacity: 1,
	    pathLength: 1,
	    fill: props.color
	}
  }

  let delay = 0
  if(props.delay) {
	delay = props.delay
  }

  return (
	<>
		<motion.svg ref={ref}
	      xmlns="http://www.w3.org/2000/svg"
	      viewBox={props.viewbox ? props.viewbox : "0 0 100 95"}
	      className="animatesvg"
	    >
	      <motion.path
	        d={props.path}
	        variants={icon}
	        initial="hidden"
	        animate={ inView ? "visible" : "hidden" }
		    transform={props.transform}
	        transition={{
	          default: { duration: 0.75, ease: "easeIn", delay: delay },
	          fill: { duration: 0.25, ease: "easeInOut", delay: 0.5 }
	        }}
	      />
	    </motion.svg>
	</>
  );
}

export default memo(AnimateSVG);
