import React, {useEffect, useRef} from "react"
import videojs from "video.js"
import "./style.scss"

function Video(props) {
  const videoNode = useRef()
  const videoProps = props
  const videoSrc = props.sources
  console.log(props.mutedState)

  useEffect(() => {
    const player = videojs(videoNode.current, videoProps, () => {
    })
    return () => {
      player.dispose()
    }
  },[])

  function clickHandle() {
    if(props.muteOnClick) {
      videoNode.current.muted = !props.mutedState
    }
  }

  return (
    <div data-vjs-player onClick={() => clickHandle()}>
      <video ref={ videoNode } className="video-js vjs-sublime-skin" playsInline>
        <track src="#.vtt" kind="captions" srcLang="en" label="english_captions"></track>
      </video>
      <div className="decorative-arrow right white"></div>
    </div>
  )
}

export default Video